<template>
  <!-- <v-form ref="form_title" v-model="valid"> -->
  <div>
    <v-row class="mt-0">
      <v-col cols="8">
        <p class="primary--text">{{$t('FEED.FIELD_TITLE')}}</p>
        <v-text-field
          v-model="title"
          @change="$emit('input', {title: $event})"
          required
          :rules="[$rules.required, $rules.maxlength(255)]"
          :placeholder="$t('FEED.FIELD_TITLE_PLACEHOLDER')"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8" class="pt-0 pb-0">
        <v-checkbox
          v-model="auto_refresh"
          @change="$emit('input', {auto_refresh: $event})"
          :label="$t('FEED.FIELD_AUTO_REFRESH')"
          :placeholder="$t('FEED.FIELD_AUTO_REFRESH')"
          outlined
          class="mt-0 pt-0"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8" class="pt-0 pb-0">
        <v-checkbox
            v-model="legacy_html_to_md"
            @change="$emit('input', {legacy_html_to_md: $event})"
            :label="$t('FEED.FIELD_LEGACY_HTML_TO_MD')"
            :placeholder="$t('FEED.FIELD_LEGACY_HTML_TO_MD')"
            outlined
            class="mt-0 pt-0"
        />
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="8" class="pt-0 pb-0">
        <v-text-field type="number" min="0" max="100"
            v-model="max_churn_percent"
            @change="$emit('input', {max_churn_percent: $event})"
            :label="$t('FEED.FIELD_MAX_CHURN_PERCENT')"
            :placeholder="$t('FEED.FIELD_MAX_CHURN_PERCENT')"
            outlined
            class="mt-0 pt-0"
        />
      </v-col>
    </v-row>
  </div>
  <!-- </v-form> -->
</template>

<script>
export default {
  name:"FormFeedCompactTitle",
  data: () => ({
    valid: false,
    //
    title: undefined,
    auto_refresh: undefined,
    legacy_html_to_md: undefined,
    max_churn_percent: undefined,
  }),
  props: {
    values: {
      type: [Object, undefined],
      default: () => ({})
    }
  },
  watch: {
    values: {
      handler () {
        this.attribValues()
      },
      deep: true,
    }
  },
  created () {
    this.attribValues()
  },
  methods: {
    attribValues () {
      const values = this.$clone(this.values)
      Object.keys(values).forEach(key => {
        this[key] = values[key]
      })
    }
  }
}
</script>
